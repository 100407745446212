<template>
    <div class="flex items-center ml-3">
        <Link :href="redirectUrl" class="flex items-center">
            <img v-if="logoExists" :src="logoPath" alt="Studio Lasso - Podgorica" class="h-10 w-40 mr-2" />
            <span v-else class="text-lg font-bold">Studio Lasso - Podgorica</span>
        </Link>
    </div>     
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
    redirectUrl: {
        type: String,
        required: true,
    },
});

const logoPath = '/images/logo/Logo-Lasso.png';
const logoExists = ref(false);

if (logoPath) {
    const img = new Image();
    img.src = logoPath;

    img.onload = () => {
        logoExists.value = true;
    };
    img.onerror = () => {
        logoExists.value = false;
    };
}
</script>
