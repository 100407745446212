<template>
    <nav ref="sidebar" class="h-screen w-64 bg-teal-900 text-white flex flex-col p-4">
        <!-- Navigation Links -->
        <ul v-if="userType === 'operator'" class="space-y-2 mt-2">
            <!-- Registration Menu Item -->
            <li>
                <button @click="toggleSubMenu" class="w-full text-white p-2 rounded flex items-center space-x-2">
                    Upravljanje korisnicima
                </button>
                <!-- Sub Menu -->
                <ul v-show="subMenuOpen" class="pl-4 space-y-2">
                    <li>
                        <Link @click="handleSubMenuClick" href="/users/operator" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Operatori
                        </Link>
                    </li>
                    <li>
                        <Link @click="handleSubMenuClick" href="/users/employer" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Poslodavci
                        </Link>
                    </li>

                    <li>
                        <Link @click="handleSubMenuClick" href="/users/worker" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Radnici
                        </Link>
                    </li>                   
                </ul>
            </li>
            <!-- Other Menu Items -->
            <li><Link @click="handleMainMenuClick" href="/jobTypes" class="p-2 rounded">Tipovi poslova</Link></li>            
            <li><Link @click="handleMainMenuClick" href="/facilityTypes" class="p-2 rounded">Tipovi objekata</Link></li>
            <li><Link @click="handleMainMenuClick" href="/languages" class="p-2 rounded">Jezici</Link></li>
            
        </ul>

        <ul v-else-if="userType === 'employer'" class="space-y-2 mt-2">
            <li><Link href="/employers" class="p-2 rounded">Profil poslodavca</Link></li>           
        </ul> 
        
        <ul v-else-if="userType === 'worker'" class="space-y-2 mt-2">
            <li><Link href="/workers" class="p-2 rounded">Profil radnika</Link></li>           
        </ul>
    </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { usePage } from '@inertiajs/vue3';

const { userType } = usePage().props;
const subMenuOpen = ref(false);
const sidebar = ref(null);

// Toggle sub-menu visibility
const toggleSubMenu = () => {
    subMenuOpen.value = !subMenuOpen.value;

};

// Close sub-menu when clicking on a sub-menu item
const handleSubMenuClick = () => {
    //subMenuOpen.value = false;
};

// Close sub-menu when clicking on any other main menu item
const handleMainMenuClick = () => {
    subMenuOpen.value = false;
};
/*
// Handle clicks outside the sidebar
const clickOutsideHandler = (event) => {
    if (sidebar.value && !sidebar.value.contains(event.target)) {
        subMenuOpen.value = false;
    }
};

// Set up and clean up event listeners
onMounted(() => {
    document.addEventListener('click', clickOutsideHandler);
});

onUnmounted(() => {
    document.removeEventListener('click', clickOutsideHandler);
});
*/
</script>

<style scoped>
/* Ensure smooth transitions for the sub-menu */
.pl-4 {
    padding-left: 1rem;
}

a, button {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

a::before, button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    /*background-color: rgba(107, 114, 128, 0.5);*/
    z-index: -1;
    border-radius: 8px;
}

a:hover, button:hover {
    background-color: rgba(107, 114, 128, 0.5);
}

ul {
    list-style: none;
    width: 100%;
    margin-top: 0.5rem; /* Smaller top margin to bring the menu closer to the top */
    padding: 0;
}
</style>
