<template>
    <header class="bg-blue-950 text-white h-20 flex items-center justify-between px-4">
        <!-- Logo Section -->      
        <div class="flex items-center">
            <ApplicationLogo redirectUrl="/" />
        </div>
        
        <!-- Center Links Section (Visible on Medium+ Screens) -->
        <div class="hidden md:flex p-2 space-x-4">
            <Link href="/registration-workers" class="text-white hover:bg-gray-600 px-4 py-2 rounded flex items-center space-x-2 whitespace-nowrap">
                Registracija za radnike
            </Link>            

            <Link href="/registration-employers" class="text-white hover:bg-gray-600 px-4 py-2 rounded flex items-center space-x-2 whitespace-nowrap">
                Registracija za poslodavce
            </Link>  
        </div>       
  
        <!-- Login Link Section (Hidden on Small Screens) -->
        <div class="hidden md:block mr-3">
            <Link href="/login" class="text-white hover:bg-gray-600 p-2 rounded flex items-center space-x-2">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="-5 -3 24 24">
                    <path fill="currentColor" d="M6.641 9.828H1a1 1 0 1 1 0-2h5.641l-1.12-1.12a1 1 0 0 1 1.413-1.415L9.763 8.12a.997.997 0 0 1 0 1.415l-2.829 2.828A1 1 0 0 1 5.52 10.95zM13 0a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1" />
                </svg>          
                <span>Prijava</span>
            </Link>            
        </div>
  
        <!-- Hamburger Icon for Mobile -->
        <div class="md:hidden">
            <button @click.stop="toggleMenu" class="text-white focus:outline-none">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
            </button>
        </div>
    </header>

    <!-- Mobile Menu -->
    <transition name="slide-fade">
        <div v-show="menuOpen" class="menu-container absolute right-4 top-14 w-72 bg-teal-900 rounded-lg shadow-lg py-2 z-50">
            <Link href="/" class="w-full text-white p-2 rounded flex items-center space-x-2" @click.stop="toggleMenu">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                    <g fill="none" fill-rule="evenodd">
                        <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                        <path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m.5 3a3.5 3.5 0 0 1 1.966 6.396l1.366 2.05a1 1 0 0 1-1.664 1.109L12.465 14H11v2a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1zm0 2H11v3h1.5a1.5 1.5 0 0 0 0-3" />
                    </g>
                </svg>
                <span>Registracija za radnike</span>
            </Link>

            <Link href="/" class="w-full text-white p-2 rounded flex items-center space-x-2" @click.stop="toggleMenu">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                    <g fill="none" fill-rule="evenodd">
                        <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                        <path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m.5 3a3.5 3.5 0 0 1 1.966 6.396l1.366 2.05a1 1 0 0 1-1.664 1.109L12.465 14H11v2a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1zm0 2H11v3h1.5a1.5 1.5 0 0 0 0-3" />
                    </g>
                </svg>
                <span>Registracija za poslodavce</span>
            </Link>

            <Link href="/login" class="w-full text-white p-2 rounded flex items-center space-x-2" @click.stop="toggleMenu">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="-5 -3 24 24">
                    <path fill="currentColor" d="M6.641 9.828H1a1 1 0 1 1 0-2h5.641l-1.12-1.12a1 1 0 0 1 1.413-1.415L9.763 8.12a.997.997 0 0 1 0 1.415l-2.829 2.828A1 1 0 0 1 5.52 10.95zM13 0a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1" />
                </svg>
                <span>Prijava</span>
            </Link>
        </div>
    </transition>    
</template>

<script setup>
import { ref } from 'vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';

const menuOpen = ref(false);

const toggleMenu = (event) => {
    event.stopPropagation();
    menuOpen.value = !menuOpen.value;
};

document.addEventListener('click', (event) => {
    if (!event.target.closest('.menu-container')) {
        menuOpen.value = false;
    }
});

window.addEventListener('resize', () => {
    if (window.innerWidth >= 768) {
        menuOpen.value = false;
    }
});
</script>

<style scoped>
/* Animation for menu appearing/sliding */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
}
.slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

a, button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative; /* For pseudo-element positioning */
    border-radius: 8px; /* Rounded corners */
}

a::before, button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px; /* Left margin */
    right: 10px; /* Right margin */
    bottom: 0;
    background-color: rgba(107, 114, 128, 0.5); /* Semi-transparent grey */
    z-index: -1; /* Behind the text */
    border-radius: 8px; /* Rounded corners */
    display: none; /* Hide initially */
}

a:hover::before, button:hover::before {
    display: block; /* Show on hover without animation */
}
</style>
